var _interopRequireDefault = require('@babel/runtime/helpers/interopRequireDefault')

exports.__esModule = true

var _getFixedGatsbyImage = _interopRequireDefault(require('./getFixedGatsbyImage'))

exports.getFixedGatsbyImage = _getFixedGatsbyImage.default

var _getFluidGatsbyImage = _interopRequireDefault(require('./getFluidGatsbyImage'))

exports.getFluidGatsbyImage = _getFluidGatsbyImage.default
